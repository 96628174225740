import type { User } from 'firebase/auth';
import type { UserProfile } from '@kcalc/lib';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { hasActiveTrial, hasSpecialAccess, logout } from '@/lib/firebase/auth';
import { hasAccess as hasAccessToSgd, setCourseData } from '@/lib/sgd';
import { updateConsultant } from '@/lib/firebase/store';

export async function onAfterAuthenticated(authUser: User, profile: UserProfile, accessToken?: string): Promise<void> {
  if (authUser.providerData[0].providerId === 'oidc.sgd') {
    if (!hasActiveTrial(profile) && !hasSpecialAccess(profile)) {
      if (accessToken) {
        try {
          const result = await hasAccessToSgd(accessToken);
          if (result) {
            if (result.trialEnd > DateTime.now().toISO()) {
              await updateConsultant(authUser.uid, {
                trialUntil: result.trialEnd,
              });

              return;
            }

            if (result.access) {
              await updateConsultant(authUser.uid, {
                specialAccesses: [
                  result.access,
                  ...profile.specialAccesses ?? [],
                ],
              });

              await lastValueFrom(setCourseData(
                result.access.reason,
                result.access.metaData?.courseId as number,
                DateTime.fromISO(result.access.metaData?.courseStart as string),
                DateTime.fromISO(result.access.until),
              ));

              return;
            }
          }
        } catch (e) {
          console.warn('[afterAuthenticated sgd] failed to call api', e);
        }
      }

      await logout();
    }
  }
}
