const u = (e, n = 2) => {
  const t = 10 ** n;
  return Math.round((e + Number.EPSILON) * t) / t;
}, y = (e, n = 2) => {
  const t = u(e, n).toString();
  return n > 0 ? t.replace(".", ",") : t;
}, f = [
  "energyKilojoules",
  "energyCal",
  "fat",
  "fattyAcidsSaturated",
  "fattyAcidsMonounsaturated",
  "fattyAcidsPolyunsaturated",
  "cholesterol",
  "carbohydrates",
  "sugar",
  "starch",
  "dietaryFiber",
  "protein",
  "salt",
  "alcohol",
  "water",
  "vitA_Betacarotene",
  "vitB1",
  "vitB2",
  "vitB6",
  "vitB12",
  "vitB3",
  "vitB9",
  "vitB5",
  "vitC",
  "vitD",
  "vitE",
  "kalium",
  "sodium",
  "chloride",
  "calcium",
  "magnesium",
  "phosphor",
  "iron",
  "iodine",
  "zinc"
], d = ["energyCal", "fat", "fattyAcidsSaturated", "carbohydrates", "sugar", "protein", "salt"], l = [...d, "dietaryFiber"], b = [...l, "fattyAcidsMonounsaturated", "fattyAcidsPolyunsaturated"], v = [
  "calcium",
  "kalium",
  "sodium",
  "magnesium",
  "chloride",
  "phosphor",
  "iron",
  "iodine",
  "zinc",
  "vitA_Betacarotene",
  "vitB1",
  "vitB2",
  "vitB3",
  "vitB5",
  "vitB6",
  "vitB9",
  "vitB12",
  "vitC",
  "vitD",
  "vitE"
], _ = [
  "vegetarian",
  "vegan",
  "lowCarb",
  "lowFat",
  "ketogenic",
  "paleo",
  "lactoseFree",
  "glutenFree",
  "fodmap",
  "sorbitolFree",
  "fructoseFree"
], m = {
  energyKilojoules: 1,
  energyCal: 1e-3,
  fat: 1e-3,
  fattyAcidsSaturated: 1e-3,
  fattyAcidsMonounsaturated: 1e-3,
  fattyAcidsPolyunsaturated: 1e-3,
  cholesterol: 1,
  carbohydrates: 1e-3,
  sugar: 1e-3,
  starch: 1e-3,
  dietaryFiber: 1e-3,
  protein: 1e-3,
  salt: 1e-3,
  alcohol: 1e-3,
  water: 1e-3,
  vitA_Betacarotene: 1e-6,
  vitB1: 1e-6,
  vitB2: 1e-6,
  vitB6: 1e-6,
  vitB12: 1e-6,
  vitB3: 1e-6,
  vitB9: 1e-6,
  vitB5: 1e-6,
  vitC: 1e-6,
  vitD: 1e-6,
  vitE: 1e-6,
  kalium: 1e-6,
  sodium: 1e-6,
  chloride: 1e-6,
  calcium: 1e-6,
  magnesium: 1e-6,
  phosphor: 1e-6,
  iron: 1e-6,
  iodine: 1e-6,
  zinc: 1e-6
}, k = [
  "Alkoholfreie Getr\xE4nke",
  "Alkoholhaltige Getr\xE4nke",
  "Aufstrich und Aufschnitt",
  "Backwaren",
  "Brote und Brotwaren",
  "Eier",
  "Fette und \xD6le",
  "Fisch",
  "Fleisch",
  "Fleischersatz",
  "Fertiggerichte",
  "H\xFClsenfr\xFCchte",
  "Cerealien",
  "Obst (Fr\xFCchte)",
  "Pilze",
  "Gem\xFCse",
  "Gerichte",
  "Getreide",
  "Gew\xFCrze, Kr\xE4uter und W\xFCrzmittel",
  "Milchprodukte",
  "N\xFCsse, Samen und Sprossen",
  "Snacks und S\xFC\xDFigkeiten",
  "Sonstige",
  "Teigwaren"
];
function c(e, n, t = {
  decimals: 3,
  applyMultiplicationFactors: !0
}) {
  const a = t.applyMultiplicationFactors ? (n ?? 0) * m[e] : n;
  return u(a ?? 0, t?.decimals);
}
function h(e, n, t = {
  decimals: 3,
  applyMultiplicationFactors: !0
}) {
  return new Intl.NumberFormat("de-DE").format(c(e, n, t));
}
function g(e) {
  return Boolean(e) && "source" in e && ["open_food_facts", "import"].includes(e.source ?? "");
}
function w(e) {
  const n = g(e);
  return {
    data: e,
    id: e.id,
    calculatedValue: (t) => c(
      t,
      t in e ? e[t] : void 0
    ),
    fieldValue: (t) => h(
      t,
      t in e ? e[t] : void 0
    ),
    isInUserPool: n ? !1 : e.source === "user_pool",
    isProduct: n
  };
}
const r = {
  0: 0,
  1: 5,
  2: 7,
  3: 10
}, F = (e, n, t, a = !0, o = !0, i = !1) => {
  let s = e.price[n];
  return n === "yearly" && i && (s *= 12), a && t.gamification?.redeemedLevel && t.gamification.redeemedLevel > 0 && r?.[t.gamification.redeemedLevel] && (s *= 1 - r[t.gamification.redeemedLevel] / 100), o && (s *= 1.19), s;
}, B = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
], A = [
  "breakfast",
  "lunch",
  "dinner",
  "snack_a",
  "snack_b",
  "snack_c",
  "post_workout",
  "pre_workout",
  "drinking",
  "between_meal_a",
  "between_meal_b",
  "between_meal_c"
], x = [
  "success",
  "error",
  "info",
  "warning"
], C = (e) => {
  const n = {
    job: {
      1: 0.95,
      2: 1.2,
      3: 1.4,
      4: 1.5,
      5: 1.6,
      6: 1.7,
      7: 1.8,
      8: 1.9,
      9: 2,
      10: 2.1,
      11: 2.2,
      12: 2.3,
      13: 2.4
    },
    freeTime: {
      1: 1.2,
      2: 1.4,
      3: 1.6,
      4: 1.9,
      5: 2.2
    }
  }, t = {
    calcium: 1e3,
    kalium: 4e3,
    sodium: 1500,
    magnesium: 350,
    chloride: 2300,
    phosphor: 700,
    iron: 15,
    iodine: 0.2,
    zinc: 14,
    vitA_Betacarotene: 10.2,
    vitB1: 1.2,
    vitB2: 1.4,
    vitB3: 15,
    vitB5: 6,
    vitB6: 1.6,
    vitB9: 0.3,
    vitB12: 4e-3,
    vitC: 110,
    vitD: 0.02,
    vitE: 14
  }, a = {
    health: [
      {
        de: "Bestehen Unvertr\xE4glichkeiten bzw. Allergien gegen spezielle Lebensmittel?",
        en: "Are there any intolerances or allergies to specific foods?"
      },
      {
        de: "Leidest du an Krankheiten? (Herzkreislaufbeschwerden, Allergien, Orthop\xE4dische Beschwerden o. \xC4.)",
        en: "Do you suffer from any diseases? (Cardiovascular problems, allergies, orthopedic complaints, etc.)"
      },
      {
        de: "Nimmst du Medikamente ein?",
        en: "Are you taking any medications?"
      },
      {
        de: "Bekannte Krankheiten in der Familie? (Diabetes, Bluthochdruck, Alzheimer, Herzinfarkt)",
        en: "Known diseases in the family? (Diabetes, high blood pressure, Alzheimer's disease, heart attack)"
      }
    ],
    eatingBehavior: [
      {
        de: "Welche Lebensmittel magst du geschmacklich nicht?",
        en: "What foods do you dislike in taste?"
      },
      {
        de: "Auf welche Lebensmittel m\xF6chtest du ungern verzichten?",
        en: "What foods would you hate to give up?"
      },
      {
        de: "Welche Nahrungsmittel schmecken dir besonders gut?",
        en: "Which foods do you like the most?"
      },
      {
        de: "Zu welcher Tageszeit hast du am meisten Hunger und wann am wenigsten?",
        en: "What time of day are you hungriest and when are you least hungry?"
      },
      {
        de: "Leidest du an Hei\xDFhungerattacken? Wenn ja um Welche Tageszeit? Wie h\xE4ufig treten diese auf?",
        en: "Do you suffer from food cravings? If so, at what time of day? How often do they occur?"
      },
      {
        de: "Wie viel Zeit nimmst du dir im Schnitt beim Essen?",
        en: "On average, how much time do you take to eat?"
      },
      {
        de: "Isst du alleine, mit Familie/Freunde/Kollegen?",
        en: "Do you eat alone, with family/friends/colleagues?"
      },
      {
        de: "Nimmst du Nahrungserga\u0308nzungen zu dir?",
        en: "Do you take nutritional supplements?"
      },
      {
        de: "Trinkst du Alkohol?",
        en: "Do you drink alcohol?"
      },
      {
        de: "Hast du in Vergangenheit schon einmal eine Dia\u0308t versucht? Wenn ja welche und mit welchem Erfolg?",
        en: "Have you ever tried a diet in the past? If so, which one and with what success?"
      }
    ],
    workout: [
      {
        de: "Wie viele Wiederholungen machst du pro Muskelgr. pro Trainingseinheit?",
        en: "How many reps do you do per muscle gr. per workout?"
      },
      {
        de: "Wie oft trainierst du in der Woche?",
        en: "How often do you train per week?"
      },
      {
        de: "Welche Trainingsprogramme hast du in der Vergangenheit ausprobiert?",
        en: "What training programs have you tried in the past?"
      },
      {
        de: "Welche \xDCbungen machst du f\xFCr diverse Muskelgruppen?",
        en: "What exercises do you do for various muscle groups?"
      },
      {
        de: "Gehst du bis ans Muskelversagen beim Training?",
        en: "Do you go to muscle failure when you work out?"
      }
    ],
    goals: [
      {
        de: "Was erwartest du von der Erna\u0308hrungsberatung?",
        en: "What do you expect from nutrition counseling?"
      },
      {
        de: "Was willst du genau erreichen und bis wann?",
        en: "What exactly do you want to achieve and by when?"
      },
      {
        de: "Was ist deine Motivation, um weiterzumachen und nicht aufzugeben?",
        en: "What is your motivation to keep going and not give up?"
      }
    ]
  }, o = [
    {
      number: 8e3,
      name: {
        de: "WhatsAppCoaching",
        en: "WhatsAppCoaching"
      }
    },
    {
      number: 8001,
      name: {
        de: "Training",
        en: "Training"
      }
    },
    {
      number: 8002,
      name: {
        de: "Ern\xE4hrungspl\xE4ne",
        en: "Nutrition plans"
      }
    },
    {
      number: 8003,
      name: {
        de: "Beratung",
        en: "Consulting"
      }
    },
    {
      number: 8200,
      name: {
        de: "Umsatzerl\xF6se",
        en: "Revenues"
      }
    },
    {
      number: 4210,
      name: {
        de: "Miete",
        en: "Rent"
      }
    },
    {
      number: 4360,
      name: {
        de: "Versicherungen",
        en: "Insurances"
      }
    },
    {
      number: 4380,
      name: {
        de: "Beitr\xE4ge",
        en: "Contributions"
      }
    },
    {
      number: 4390,
      name: {
        de: "Sonstige Abgaben",
        en: "Other charges"
      }
    },
    {
      number: 4600,
      name: {
        de: "Werbekosten",
        en: "Advertising costs"
      }
    },
    {
      number: 4650,
      name: {
        de: "Bewirtungskosten",
        en: "Hospitality costs"
      }
    },
    {
      number: 4673,
      name: {
        de: "Fahrtkosten",
        en: "Travel costs"
      }
    },
    {
      number: 4910,
      name: {
        de: "Porto",
        en: "Petty cash"
      }
    },
    {
      number: 4920,
      name: {
        de: "Telefon",
        en: "Phone"
      }
    },
    {
      number: 4930,
      name: {
        de: "B\xFCrobedarf",
        en: "Office supplies"
      }
    },
    {
      number: 4945,
      name: {
        de: "Fortbildungskosten",
        en: "Training costs"
      }
    },
    {
      number: 4950,
      name: {
        de: "Rechts- und Beratungskosten",
        en: "Legal and consulting fees"
      }
    },
    {
      number: 4955,
      name: {
        de: "Buchf\xFChrungskosten",
        en: "Accounting costs"
      }
    },
    {
      number: 4980,
      name: {
        de: "Sonstiger Aufwand",
        en: "Other expenses"
      }
    }
  ];
  return {
    palFactors: n,
    microNutrientReferenceValues: t,
    needsAnalysisQuestions: Object.fromEntries(
      Object.keys(a).map((i) => [
        i,
        a[i].map((s) => s[e])
      ])
    ),
    accounting: {
      accounts: o.map((i) => ({
        number: i.number,
        name: i.name[e]
      })),
      invoice: {
        currency: "EUR"
      }
    }
  };
}, W = ["open", "paid"], z = [
  "zyBOxQdRvqeyNaTA9vGL",
  "VnQlORiMVtnIDVFkw89x",
  "rp8hhmK7z9SEVuvQP0BT",
  "OYvepz3yZOonY2afjEjs",
  "2Etvp88ncbwWe66b7ssY"
], E = ["basic", "business"], T = ["male", "female"], D = [
  "complete_personal_data",
  "choose_favorite_food",
  "create_customer",
  "create_needs_analysis",
  "create_nutrition_plan",
  "create_recipe"
], M = [
  "harris_benedict",
  "dge",
  "bmi",
  "de_lorenzo",
  "cunningham"
], P = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13], S = [1, 2, 3, 4, 5], N = [
  "health",
  "eatingBehavior",
  "workout",
  "goals"
], O = [
  "44zero",
  "otl",
  "ecodemy",
  "drfreese",
  "uvr",
  "sgd"
], L = [
  "subscription_created",
  "subscription_recurring_payment_failed",
  "subscription_recurring_payment_succeeded",
  "payment_method_changed",
  "email_verification_request",
  "subscription_payment_method_update_succeeded",
  "subscription_upgrade_succeeded",
  "subscription_payment_charged_back",
  "admin_subscription_payment_charged_back"
], H = [
  !1,
  "up_to_4_month",
  "after_4_month_full",
  "after_4_month_partial"
], V = [
  "gain_weight",
  "keep_weight",
  "loose_weight"
], j = [
  "body_weight"
];
function p(e) {
  return !!e.ingredients;
}
function I(e) {
  return !p(e);
}
export {
  b as big10,
  d as big7,
  l as big8,
  M as bmrFormulas,
  H as breastfeedingOptions,
  w as createFoodHelper,
  j as customerLogEntryTypes,
  z as defaultPlanIds,
  C as defaultUserProfileSettings,
  _ as diets,
  k as foodCategories,
  S as freeTimePalFactorsKeys,
  T as genders,
  c as getCalculatedFieldValue,
  h as getFormattedFieldValue,
  F as getPlanPriceForUser,
  V as goalTypes,
  I as isFood,
  p as isRecipe,
  P as jobPalFactorsKeys,
  A as mealTypes,
  v as microNutrientFields,
  N as needsAnalysisQuestionCategories,
  m as nutrientFieldMultiplicationFactors,
  f as nutrientFields,
  D as onboardingSteps,
  O as platformCodes,
  g as recordIsProduct,
  y as roundAndFormatNumber,
  u as roundNumber,
  r as subscriptionBenefitPercentage,
  E as subscriptionPlanCodes,
  L as systemEmailCode,
  x as systemMessageTypes,
  W as transactionStates,
  B as weekdays
};
