<script setup lang="ts">
import { computed } from 'vue';
import type { Customer } from '@kcalc/lib';
import { useAvatar } from '@/composables/avatars';
import { useAuth } from '@/composables/firebase/auth';

interface Props {
  customer?: Customer;
  size: number
}

const props = defineProps<Props>();

const { user } = useAuth();
const profile = computed(() => (props.customer ? props.customer.profile : user.value?.profile));
const hasCustomAvatar = computed(() => !!profile.value?.avatar);
const forUser = computed(() => !props.customer);

const seed = computed(() => {
  if (forUser.value) {
    return user.value?.authUser.uid as string;
  }

  return props.customer?.id as string;
});

const avatar = computed(() => {
  if (hasCustomAvatar.value) {
    return profile.value?.avatar as string;
  }

  return useAvatar(seed.value, { size: props.size * 2 }).uri;
});

const username = profile.value?.personalData ? `${profile.value?.personalData.firstName} ${profile.value?.personalData.surname}` : undefined;
</script>

<template>
  <div>
    <div
      v-if="hasCustomAvatar"
      class="user-avatar"
      :style="{ width: `${props.size}px`, height: `${props.size}px`, backgroundImage: `url(${avatar})` }" />

    <img
      v-if="!hasCustomAvatar"
      :width="size"
      :src="avatar"
      :alt="username">
  </div>
</template>

<style scoped>
.user-avatar {
  border-radius: 50%;
  background-size: cover;
}
img {
  max-width: none;
  display: block;
}
</style>
