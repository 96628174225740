import { ref, readonly } from 'vue';
import type { MaybeNull, MaybeUndefined, SystemMessageType } from '@kcalc/lib';
import { systemMessageTypes } from '@kcalc/lib';
import { filter } from 'rxjs';
import { t } from '@/lib/i18n';
import { user$ } from '@/lib/firebase/auth';
import { trackEvent } from '@/lib/analytics';
import { useAuth } from '@/composables/firebase/auth';
import { trackFbEvent } from '@/lib/facebook/analytics';

export interface SystemMessage {
  type: SystemMessageType;
  i18nKey: string; // Full path to the message in the i18n json files
  message: () => string;
  code?: string;
  shouldCelebrate?: boolean;
}

const systemMessage = ref<MaybeNull<SystemMessage>>(null);

const shouldCelebrate = (code?: string) => {
  if (!code) {
    return false;
  }

  return [
    'success_subscription_created',
    'success_subscription_upgrade',
  ].includes(code);
};

const setSystemMessage = (i18nKey: string, type: SystemMessageType = 'info') => {
  const code = i18nKey.split('.').pop();

  if (code === 'success_subscription_created') {
    const { user } = useAuth();

    if (user.value?.profile?.subscription) {
      const { subscription } = user.value.profile;
      trackEvent('purchase', {
        currency: subscription.amount.currency,
        value: parseFloat(subscription.amount.value),
        transaction_id: subscription.id,
        items: [
          {
            item_id: subscription.plan,
          },
        ],
      });

      if (import.meta.env.VITE_GOOGLE_ADS_ID) {
        trackEvent('conversion', {
          send_to: `${import.meta.env.VITE_GOOGLE_ADS_ID}/N36wCNuO25cYENzzk6Up`,
          value: parseFloat(subscription.amount.value),
          currency: subscription.amount.currency,
          transaction_id: subscription.id,
        });
      }

      trackFbEvent('Subscribe', {
        value: parseFloat(subscription.amount.value),
        currency: subscription.amount.currency,
      });
    }
  }

  systemMessage.value = {
    type,
    i18nKey,
    message: () => t(i18nKey),
    code,
    shouldCelebrate: shouldCelebrate(code),
  };
};

const setSystemMessageByCode = (code: string) => {
  let type: MaybeUndefined<SystemMessageType> = code.split('_').shift() as MaybeUndefined<SystemMessageType>;

  if (!type || !systemMessageTypes.includes(type as SystemMessageType)) {
    type = 'info';
  }

  setSystemMessage(`systemMessages.${code}`, type);
};

const clearSystemMessage = () => {
  systemMessage.value = null;
};

user$.pipe(
  filter(Boolean),
  filter((u) => !!u.profile?.systemMessage),
).subscribe((u) => {
  setSystemMessageByCode(u.profile?.systemMessage as string);
  u.updateProfile({
    systemMessage: undefined,
  });
});

export function useSystemMessage() {
  return {
    systemMessage: readonly(systemMessage),
    setSystemMessage,
    setSystemMessageByCode,
    clearSystemMessage,
  };
}
