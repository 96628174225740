import type { PlatformCode } from '@kcalc/lib';
import { platformCodes } from '@kcalc/lib';

export { version as appVersion } from '../../package.json';

export const isValidPlatformCode = (code: string): boolean => platformCodes.includes(code as PlatformCode);

export const platformCode: PlatformCode = (() => {
  if (import.meta.env.MODE && platformCodes.includes(import.meta.env.MODE as PlatformCode)) {
    return import.meta.env.MODE as PlatformCode;
  }

  if (import.meta.env.VITE_EMULATE && isValidPlatformCode(import.meta.env.VITE_EMULATE)) {
    return import.meta.env.VITE_EMULATE;
  }

  return '44zero';
})();

export const isPlatform = (code: PlatformCode) => platformCode === code;

export const is44zero = isPlatform('44zero');
export const isThirdParty = !isPlatform('44zero');

export const platformUrls: Record<PlatformCode, string> = {
  '44zero': 'https://app.kcalculator.de',
  otl: 'https://otl.kcalculator.de',
  uvr: 'https://uvr.kcalculator.de',
  ecodemy: 'https://ecodemy.kcalculator.de',
  drfreese: 'https://dr-freese.kcalculator.de',
  sgd: 'https://sgd.kcalculator.de',
};

export const platformUrl = platformUrls[platformCode];
