import type {
  PointRecord, Points, UserActionReward, UserLevel,
} from '@kcalc/lib';
import type { User } from 'firebase/auth';
import { DateTime } from 'luxon';
import { addPointsForConsultant, pointsForConsultant } from '@/lib/firebase/store';

// https://app.clickup.com/t/2n9z17y
const pointMatrix: {
  [action: string]: number
} = {
  create_customer: 1000,
  create_nutrition_plan: 1000,
  create_needs_analysis: 1000,
  create_recipe: 1000,
  share_recipe: 1000,
  week: 10000,
  quarter: 30000,
  year: 100000,
};

export const levelMatrix: {
  [level: number]: number
} = {
  1: 50000,
  2: 570000,
  3: 1180000,
};

export const addPointsForAction = async (userId: string, action: UserActionReward, points?: number): Promise<void> => {
  points = pointMatrix[action as string] ?? points;

  if (!points) {
    throw new Error(`Invalid action provided "${action}"`);
  }

  await addPointsForConsultant(
    userId,
    {
      action,
      points,
      created: new Date().toUTCString(),
    },
  );
};

export const getPoints = async (user: User, verifiedAt?: number): Promise<Points> => {
  const pointsRef = await pointsForConsultant(user.uid);
  const computedPoints = ((): PointRecord[] => {
    if (!user.emailVerified) {
      return [];
    }

    let records: PointRecord[] = [
      {
        points: 40000,
        action: 'validated',
      },
    ];

    // TODO incorporate "purchasedAt"
    // if the user had 1 week free period, two month paid plan, 1 year pause and resumes afterwards, there should be no points for the year pause
    const startDate = DateTime.fromMillis(verifiedAt ?? (user.metadata as any).createdAt);
    const weeks = Math.floor(Math.abs(startDate.diffNow('weeks').weeks));
    const quarters = Math.floor(Math.abs(startDate.diffNow('quarters').quarters));
    const years = Math.floor(Math.abs(startDate.diffNow('years').years));

    console.log(startDate.toISODate());
    console.log(`weeks: ${weeks}`);
    console.log(`quarters: ${quarters}`);
    console.log(`years: ${years}`);

    if (weeks > 0) {
      records = [
        ...records,
        {
          points: weeks * pointMatrix.week,
          action: 'weeks',
        },
      ];
    }

    if (quarters > 0) {
      records = [
        ...records,
        {
          points: quarters * pointMatrix.quarter,
          action: 'quarters',
        },
      ];
    }

    if (years > 0) {
      records = [
        ...records,
        {
          points: years * pointMatrix.year,
          action: 'years',
        },
      ];
    }

    return records;
  })();

  return {
    staticPoints: pointsRef.docs.map<PointRecord>((doc) => doc.data()),
    computedPoints,
  };
};

export const getTotalPoints = async (user: User, verifiedAt?: number): Promise<number> => {
  const points = await getPoints(user, verifiedAt);

  return [
    ...points.staticPoints,
    ...points.computedPoints,
  ].reduce(
    (total, record) => total + record.points,
    0,
  );
};

export const getLevel = async (user: User, verifiedAt?: number): Promise<UserLevel> => {
  const totalPoints = await getTotalPoints(user, verifiedAt);

  if (totalPoints < levelMatrix[1]) {
    return 0;
  }

  if (totalPoints >= levelMatrix[1] && totalPoints < levelMatrix[2]) {
    return 1;
  }

  if (totalPoints >= levelMatrix[2] && totalPoints < levelMatrix[3]) {
    return 2;
  }

  return 3;
};

export const formatPoints = (points: number) => Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 2,
}).format(points);
