import type {
  DocumentData,
  QuerySnapshot,
} from 'rxfire/firestore/interfaces';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  deleteField,
  DocumentSnapshot,
  DocumentReference,
} from 'firebase/firestore';
import { docData } from 'rxfire/firestore';
import type {
  ActivityPlan,
  CustomerInvoice,
  CustomerLogEntry,
  CustomerProfile,
  GenericObject,
  MaybeUndefined,
  NeedsAnalysis,
  NutritionPlan,
  PointRecord,
  Recipe,
  Transaction,
  UserProfile,
  UserProfileArrayField,
} from '@kcalc/lib';
import type { PartialDeep } from 'type-fest';
import { DateTime } from 'luxon';
import { weekdays } from '@kcalc/lib';
import { db } from '@/lib/firebase/app';

// Influenced by a great article from Jamie Curnow
// https://medium.com/swlh/using-firestore-with-typescript-65bd2a602945

const converter = <T extends DocumentData>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snapshot: DocumentSnapshot) => snapshot.data() as T,
});

const prepareData = <T extends GenericObject>(data: T, options?: { useDeleteField: boolean }): T => {
  const {
    useDeleteField = true,
  } = options ?? {};

  return Object.keys(data).reduce(
    (document, key) => {
      // Disallow field-list (mainly Typesense meta fields)
      if (['_highlightResult', '_snippetResult', 'text_match_info'].includes(key)) {
        return document;
      }

      const value = data[key];

      if (typeof value === 'object' && !Array.isArray(value) && value?._methodName !== 'deleteField' && value?._methodName !== 'arrayUnion') {
        return {
          ...document,
          [key]: prepareData(value),
        };
      }

      if ((!value && typeof value !== 'boolean' && value !== 0) || value === '') {
        if (useDeleteField) {
          return {
            ...document,
            [key]: deleteField(),
          };
        }

        return document;
      }

      return {
        ...document,
        [key]: value,
      };
    },
    {} as T,
  );
};

export const typedCollection = <T extends DocumentData>(collectionPath: string) => collection(db, collectionPath).withConverter(converter<T>());
export const typedSubCollection = <T extends DocumentData>(parentCollection: DocumentReference<any, any>, path: string) => (
  collection(parentCollection, path).withConverter(converter<T>())
);

export const consultants = typedCollection<UserProfile>('consultants');

export const consultant = (id: string) => doc<UserProfile, UserProfile>(consultants, id);

export const consultant$ = (id: string) => docData<UserProfile>(consultant(id));

export const updateConsultant = (id: string, update: PartialDeep<UserProfile>) => (
  setDoc<UserProfile, UserProfile>(consultant(id), prepareData(update), { merge: true })
);

export const toggleConsultantCollectionFieldEntry = async (userId: string, field: keyof UserProfile, entry: string) => {
  const userRef = consultant(userId);
  const fieldValues = await getDoc(userRef)
    .then((snap) => snap.data() as UserProfile)
    .then((data) => data[field] as MaybeUndefined<string[]>);

  await updateDoc(userRef, {
    [field]: (fieldValues ?? []).includes(entry) ? arrayRemove(entry) : arrayUnion(entry),
  });
};

export const addToConsultantArrayField = async (userId: string, field: UserProfileArrayField, value: string) => updateDoc(
  consultant(userId),
  {
    [field]: arrayUnion(value),
  },
);

export const addPointsForConsultant = (id: string, record: PointRecord) => addDoc<PointRecord, PointRecord>(
  typedCollection<PointRecord>(`consultants/${id}/points`),
  record,
);

export const pointsForConsultant = (id: string): Promise<QuerySnapshot<PointRecord>> => getDocs<PointRecord, PointRecord>(
  typedCollection<PointRecord>(`consultants/${id}/points`),
);

export const customer = (userId: string, customerId: string) => (
  doc<CustomerProfile, CustomerProfile>(typedSubCollection(consultant(userId), 'customers'), customerId)
);
export const customerProfile$ = (userId: string, customerId: string) => docData<CustomerProfile>(customer(userId, customerId));

export const updateCustomer = (userId: string, customerId: string, update: PartialDeep<CustomerProfile>) => (
  setDoc<CustomerProfile, CustomerProfile>(
    customer(userId, customerId),
    prepareData({ ...update, updatedAt: DateTime.utc().toISO() }),
    { merge: true },
  )
);

export const createCustomer = async (userId: string, data: PartialDeep<CustomerProfile>) => (
  (await addDoc(typedSubCollection(consultant(userId), 'customers'), {
    ...prepareData(data),
    createdAt: DateTime.utc().toISO(),
    updatedAt: DateTime.utc().toISO(),
  })).id
);

export const deleteCustomer = async (userId: string, customerId: string) => {
  await deleteDoc(customer(userId, customerId));
};

const needsAnalysis = (userId: string, analysisId: string) => (
  doc<NeedsAnalysis, NeedsAnalysis>(typedSubCollection<NeedsAnalysis>(consultant(userId), 'needsAnalyses'), analysisId)
);
export const needsAnalysis$ = (userId: string, analysisId: string) => (
  docData<NeedsAnalysis>(doc<NeedsAnalysis, NeedsAnalysis>(typedSubCollection(consultant(userId), 'needsAnalyses'), analysisId), { idField: 'id' })
);

export const createNeedsAnalysis = async (userId: string, customerId: string) => (
  (await addDoc(typedSubCollection(consultant(userId), 'needsAnalyses'), {
    createdAt: DateTime.utc().toISO(),
    updatedAt: DateTime.utc().toISO(),
    customerId,
  })).id
);

export const deleteNeedsAnalysis = async (userId: string, needsAnalysisId: string) => {
  await deleteDoc(needsAnalysis(userId, needsAnalysisId));
};

export const updateNeedsAnalysis = async (userId: string, needsAnalysisId: string, data: PartialDeep<NeedsAnalysis>) => (
  setDoc<NeedsAnalysis, NeedsAnalysis>(
    needsAnalysis(userId, needsAnalysisId),
    prepareData({ ...data, updatedAt: DateTime.utc().toISO() }),
    { merge: true },
  )
);

export const addCustomerLogEntry = async (userId: string, customerId: string, data: PartialDeep<CustomerLogEntry>) => (
  (await addDoc(collection(consultant(userId), 'logEntries'), {
    ...data,
    createdAt: DateTime.utc().toISO(),
    updatedAt: DateTime.utc().toISO(),
    customerId,
  })).id
);

export const deleteCustomerLogEntry = async (userId: string, logEntryId: string) => {
  await deleteDoc(doc(collection(consultant(userId), 'logEntries'), logEntryId));
};

export const addActivityPlan = async (userId: string, customerId: string, data: PartialDeep<ActivityPlan>) => (
  (await addDoc(collection(consultant(userId), 'activityPlans'), {
    ...data,
    createdAt: DateTime.utc().toISO(),
    updatedAt: DateTime.utc().toISO(),
    customerId,
  })).id
);

export const updateActivityPlan = async (userId: string, activityPlanId: string, data: PartialDeep<ActivityPlan>) => (
  setDoc<ActivityPlan, ActivityPlan>(
    doc<ActivityPlan, ActivityPlan>(typedSubCollection<ActivityPlan>(consultant(userId), 'activityPlans'), activityPlanId),
    prepareData({ ...data, updatedAt: DateTime.utc().toISO() }),
    { merge: true },
  )
);

export const deleteActivityPlan = async (userId: string, activityPlanId: string) => {
  await deleteDoc(doc(collection(consultant(userId), 'activityPlans'), activityPlanId));
};

export const nutritionPlanDoc = (userId: string, planId: string) => (
  doc<NutritionPlan, NutritionPlan>(typedSubCollection(consultant(userId), 'nutritionPlans'), planId)
);
export const nutritionPlan$ = (userId: string, planId: string) => (
  docData<NutritionPlan>(nutritionPlanDoc(userId, planId), { idField: 'id' })
);

function prepareNutritionPlanData(data: PartialDeep<NutritionPlan>): PartialDeep<NutritionPlan> {
  if (data.weekdays) {
    data.weekdays = data.weekdays.sort((a, b) => weekdays.indexOf(a) - weekdays.indexOf(b));
  }

  return data;
}

export const addNutritionPlan = async (userId: string, data: PartialDeep<NutritionPlan>) => (
  (await addDoc(collection(consultant(userId), 'nutritionPlans'), prepareNutritionPlanData({
    ...data,
    createdAt: DateTime.utc().toISO(),
    updatedAt: DateTime.utc().toISO(),
  }))).id
);

export const deleteNutritionPlan = async (userId: string, planId: string) => {
  await deleteDoc(nutritionPlanDoc(userId, planId));
};

export const updateNutritionPlan = async (userId: string, planId: string, data: PartialDeep<NutritionPlan>) => (
  setDoc<NutritionPlan, NutritionPlan>(
    nutritionPlanDoc(userId, planId),
    prepareData(prepareNutritionPlanData({ ...data, updatedAt: DateTime.utc().toISO() })),
    { merge: true },
  )
);

const consultantRecipes = (userId: string) => typedSubCollection<Recipe>(consultant(userId), 'recipes');

const consultantRecipe = (userId: string, recipeId: string) => doc<Recipe, Recipe>(consultantRecipes(userId), recipeId);

export const consultantRecipe$ = (userId: string, recipeId: string) => docData<Recipe>(consultantRecipe(userId, recipeId));

export async function updateRecipe(userId: string, recipe: Partial<Recipe>) {
  return setDoc<Recipe, Recipe>(consultantRecipe(userId, recipe.id as string), prepareData(recipe), { merge: true });
}

export async function addRecipe(userId: string, recipe: Recipe) {
  await updateRecipe(userId, recipe);
}

export async function deleteRecipe(userId: string, recipeId: string) {
  return deleteDoc(consultantRecipe(userId, recipeId));
}

const consultantTransactions = (userId: string) => typedSubCollection<Transaction>(consultant(userId), 'transactions');

export async function getLatestInvoiceId(userId: string): Promise<MaybeUndefined<string>> {
  return getDocs<Transaction, Transaction>(
    query<Transaction, Transaction>(
      consultantTransactions(userId),
      where('type', '==', 'invoice'),
      orderBy('documentId', 'desc'),
      limit(1),
    ),
  ).then((snap) => (snap.docs[0]?.data() as CustomerInvoice)?.documentId);
}
