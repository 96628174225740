import type { SearchClient } from 'algoliasearch';
import { computed, unref } from 'vue';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import type {
  CollectionSearchParametersOptionalQueryBy,
  SearchParametersOptionalQueryBy,
  SearchParametersWithQueryBy,
  TypesenseInstantsearchAdapterOptions,
} from 'typesense-instantsearch-adapter-shims';
import type { MaybeNull, MaybeUndefined } from '@kcalc/lib';
import type { ConfigurationOptions } from 'typesense/lib/Typesense/Configuration';
import { Client } from 'typesense';
import type { SearchParams } from 'typesense/lib/Typesense/Documents';
import { useAuth } from '@/composables/firebase/auth';

interface Config {
  sort_by?: string;
  hidden_hits?: string;
  filter_by?: any;
}

const nodes = [
  {
    host: import.meta.env.VITE_TYPESENSE_HOST,
    port: import.meta.env.VITE_TYPESENSE_PORT,
    protocol: import.meta.env.VITE_TYPESENSE_PROTOCOL,
  },
];

function getSafeSearchParams(params: SearchParametersOptionalQueryBy): SearchParametersWithQueryBy {
  if (!params.query_by) {
    params.query_by = 'title';
  }

  if (!params.prioritize_token_position) {
    params.prioritize_token_position = true;
  }

  return params as SearchParametersWithQueryBy;
}

export function useTypesense(
  searchParams?: SearchParametersWithQueryBy,
  collectionSpecificSearchParameters?: CollectionSearchParametersOptionalQueryBy,
) {
  const { user } = useAuth();

  const readKey = unref(user)?.credentials?.typesenseApiKeys.readKey.key;
  const writeKey = unref(user)?.credentials?.typesenseApiKeys.writeKey.key;

  const readonlyConfig = computed(() => {
    if (readKey) {
      return {
        apiKey: readKey,
        nodes,
      };
    }

    return null;
  });

  const writeConfig = computed(() => {
    if (writeKey) {
      return {
        apiKey: writeKey,
        nodes,
      };
    }

    return null;
  });

  let latestConfig: TypesenseInstantsearchAdapterOptions = {
    server: readonlyConfig.value as ConfigurationOptions,
    additionalSearchParameters: searchParams ?? { query_by: 'title' },
    collectionSpecificSearchParameters: collectionSpecificSearchParameters ?? {},
  };

  const adapter = computed<MaybeNull<TypesenseInstantsearchAdapter>>(() => {
    if (readonlyConfig.value) {
      return new TypesenseInstantsearchAdapter(latestConfig);
    }

    return null;
  });

  function getClient(type: 'read' | 'write') {
    if (type === 'read' && readonlyConfig.value) {
      return new Client(readonlyConfig.value);
    }

    if (type === 'write' && writeConfig.value) {
      return new Client(writeConfig.value);
    }

    return null;
  }

  // for instantsearch
  const searchClient = computed<MaybeNull<SearchClient>>(() => adapter?.value?.searchClient);

  const writeClient = getClient('write');
  const readClient = getClient('read');
  // eslint-disable-next-line @typescript-eslint/ban-types
  const upsert = <T extends {}>(collection: string, document: T) => writeClient?.collections(collection).documents().upsert(document);
  const deleteDoc = (collection: string, id: string) => writeClient?.collections(collection).documents(id).delete();
  const countResultsForCriteria = (collection: string, searchParams: SearchParams): MaybeUndefined<Promise<number>> => readClient
    ?.collections(collection)
    .documents()
    .search(searchParams)
    .then((result) => result.found);

  return {
    getClient,
    upsert,
    deleteDoc,
    searchClient,
    updateConfig: (config: Config) => {
      latestConfig = {
        ...latestConfig,
        additionalSearchParameters: getSafeSearchParams({
          ...latestConfig.additionalSearchParameters,
          ...config,
        }),
      };

      adapter.value?.updateConfiguration(latestConfig);
    },
    updateConfigForCollection: (collection: string, config: Config) => {
      latestConfig = {
        ...latestConfig,
        collectionSpecificSearchParameters: {
          [collection]: getSafeSearchParams({
            ...latestConfig.collectionSpecificSearchParameters?.[collection] ?? {},
            ...config,
          }),
        },
      };

      adapter.value?.updateConfiguration(latestConfig);
    },
    countResultsForCriteria,
  };
}
