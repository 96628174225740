import { ofetch } from 'ofetch';
import type { NewsEntry, PlatformCode } from '@kcalc/lib';
import { platformCode } from '@/lib/platform';

const defaultHeaders: Record<string, string> = {
  Platform: platformCode,
};

const backendApiClient = ofetch.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL}/api/v1`,
  headers: {
    ...defaultHeaders,
  },
});

export const fetchNews = async () => backendApiClient<NewsEntry[]>('/news');

export const setLikesForRecipe = (recipeId: string, platform: PlatformCode, likeChange: 1 | -1, token: string) => backendApiClient(
  `/recipe/${recipeId}/like`,
  {
    method: 'POST',
    headers: {
      Platform: platform,
      Authorization: `Bearer ${token}`,
    },
    body: {
      likeChange,
    },
  },
);

export const addUsageForRecipe = (recipeId: string, platform: PlatformCode, token: string) => backendApiClient(
  `/recipe/${recipeId}/usage`,
  {
    method: 'POST',
    headers: {
      Platform: platform,
      Authorization: `Bearer ${token}`,
    },
  },
);

export const submitRecipeToMainPlatform = (recipeId: string, platform: PlatformCode, token: string) => backendApiClient(
  `/recipe/${recipeId}`,
  {
    method: 'POST',
    headers: {
      Platform: platform,
      Authorization: `Bearer ${token}`,
    },
  },
);

export const submitSubscriptionChurnReport = (payload: Record<string, unknown>) => backendApiClient(
  '/utils/subscription/churn',
  {
    method: 'POST',
    body: payload,
  },
);
