import { ofetch } from 'ofetch';
import type { MaybeNull, SpecialAccess, SgdVerifyAccessTokenResponse } from '@kcalc/lib';
import { DateTime } from 'luxon';
import { setCRMAttributes$ } from '@/lib/firebase/functions';

const apiClient = ofetch.create({
  baseURL: `${import.meta.env.VITE_SGD_BASE_URL}/api/v2`,
});

export const verifyAccessToken = (token: string) => apiClient<SgdVerifyAccessTokenResponse>(
  '/extensions/kcalculator/verify-access-token',
  {
    method: 'POST',
    body: {
      accessToken: token,
    },
  },
);

export type HasAccessResult = MaybeNull<{
  access?: SpecialAccess;
  trialEnd: string; // iso
}>;

export const hasAccess = (token: string): Promise<HasAccessResult> => new Promise((resolve) => {
  verifyAccessToken(token).then((result) => {
    console.log('[sgd] verify access token result', result);

    if (result.userAccess.hasAccessForAnyEnrollment) {
      const enrollments = result.userAccess.enrollments.filter((enrollment) => enrollment.hasAccess);
      const mostRelevantEnrollment = enrollments.sort((a, b) => b.trialAccessEndDate - a.trialAccessEndDate)[0];
      const activeCourses = enrollments.flatMap((enrollment) => enrollment.courseAccessSpans.filter((course) => course.hasSpanAccess));
      const mostRelevantCourse = activeCourses.sort((a, b) => b.end - a.end)[0];
      const trialEnd = DateTime.fromMillis(mostRelevantEnrollment.trialAccessEndDate).toISO();

      if (mostRelevantCourse && trialEnd) {
        const until = DateTime.fromMillis(mostRelevantCourse.end).toISO();

        if (until) {
          return resolve({
            access: {
              reason: mostRelevantCourse.name,
              until,
              provider: 'sgd',
              plan: 'business',
              metaData: {
                enrollmentId: mostRelevantEnrollment.enrollmentId,
                curriculumName: mostRelevantEnrollment.curriculumName,
                courseId: mostRelevantCourse.id,
                courseStart: DateTime.fromMillis(mostRelevantCourse.start).toISO() ?? undefined,
              },
            },
            trialEnd,
          });
        }
      }

      if (mostRelevantEnrollment?.trialAccessEndDate > DateTime.local().toMillis() && trialEnd) {
        return resolve({
          trialEnd,
        });
      }
    }

    return resolve(null);
  }).catch((e) => {
    console.warn('[sgd] failed to verify access token', e);

    resolve(null);
  });
});

export const setCourseData = (title: string, id: number, start: DateTime, end: DateTime) => {
  const attributes: Record<string, string> = {
    SGD_CONTRACT: title,
  };

  const startISO = start.toISO();
  if (startISO) {
    attributes[`SGD_${id}_START`] = startISO.substring(0, 10);
  }

  const endISO = end.toISO();
  if (endISO) {
    attributes[`SGD_${id}_END`] = endISO.substring(0, 10);
  }

  return setCRMAttributes$(attributes);
};
