<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import type { MaybeUndefined, NewsEntry } from '@kcalc/lib';
import { gte } from 'semver/index';
import { useRouter } from 'vue-router';
import { useAuth } from '@/composables/firebase/auth';
import { useNews } from '@/composables/news';
import NewsModal from '@/components/dashboard/NewsModal.vue';
import { appVersion } from '@/lib/platform';
import { newsTrigger$ } from '@/directives/NewsTrigger';

const { user, isVerified, hasAccess } = useAuth();
const { newsEntries } = useNews();
const { currentRoute } = useRouter();

const latestNewsNotification = ref<MaybeUndefined<NewsEntry>>();
const userIsReady = computed<boolean>(() => isVerified.value && hasAccess.value);

const newsNotifications = computed<MaybeUndefined<NewsEntry[]>>(() => newsEntries.value
  ?.filter((entry) => {
    if (!entry.notification?.uid) {
      return false;
    }

    if (entry.notification.versionGte && !gte(appVersion, entry.notification.versionGte)) {
      return false;
    }

    if (entry.notification.onPage && !currentRoute.value.path.startsWith(entry.notification.onPage)) {
      return false;
    }

    return !user.value?.profile?.seenNotifications?.includes(entry.notification.uid);
  }));

function dismissNotification(newsEntry: MaybeUndefined<NewsEntry>) {
  if (newsEntry && user.value) {
    user.value.updateProfile({
      seenNotifications: [
        ...(user.value.profile?.seenNotifications ?? []),
        newsEntry.notification?.uid ?? '',
      ],
    }).then(() => {
      latestNewsNotification.value = undefined;
    });
  }
}

watchEffect(() => {
  if (newsNotifications.value) {
    const notifications: NewsEntry[] = JSON.parse(JSON.stringify(newsNotifications.value));

    // eslint-disable-next-line prefer-destructuring
    latestNewsNotification.value = notifications.sort((a, b) => b.publishDate.localeCompare(a.publishDate))[0];
  }
});

newsTrigger$.subscribe((newsId) => {
  if (newsId && newsEntries.value) {
    const newsEntry = newsEntries.value.find((entry) => entry.notification?.uid === newsId);

    if (newsEntry) {
      latestNewsNotification.value = newsEntry;
    }
  }
});
</script>

<template>
  <NewsModal
    v-if="userIsReady && latestNewsNotification"
    :news-entry="latestNewsNotification"
    @close="dismissNotification(latestNewsNotification)" />
</template>
