import { DateTime } from 'luxon';
import type {
  BmrFormula,
  BreastfeedingOption,
  CustomerProfile,
  FreeTimePalFactorKey,
  Gender,
  JobPalFactorKey,
  NeedsAnalysis,
  NeedsAnalysisObject,
  User,
} from '@kcalc/lib';
import type { PartialDeep } from 'type-fest';
import { freeTimePalFactorsKeys, jobPalFactorsKeys, roundNumber } from '@kcalc/lib';
import { getDoc } from 'firebase/firestore';
import { t } from '@/lib/i18n';
import { customer as customerDoc, updateNeedsAnalysis } from '@/lib/firebase/store';
import { useAppConfig } from '@/composables/app-config';

export const calcFatFreeMass = (bodyWeight: number, bodyFat: number): number => (
  roundNumber(bodyWeight - ((bodyWeight / 100) * bodyFat))
);

export const calcTHQ = (hipCircumference: number, waistCircumference: number): number => (
  roundNumber(waistCircumference / hipCircumference)
);

export const calcBMI = (bodyWeight: number, bodySize: number): number => (
  roundNumber(bodyWeight / ((bodySize / 100) ** 2), 1)
);

export const getBMILevel = (bmi: number): string => {
  if (bmi < 18.5) {
    return t('NeedsAnalysis.bmi.levels.underweight');
  }

  if (bmi < 24.9) {
    return t('NeedsAnalysis.bmi.levels.normalWeight');
  }

  if (bmi < 29.9) {
    return t('NeedsAnalysis.bmi.levels.overweight1');
  }

  if (bmi < 34.9) {
    return t('NeedsAnalysis.bmi.levels.overweight2');
  }

  if (bmi < 39.9) {
    return t('NeedsAnalysis.bmi.levels.overweight3');
  }

  return t('NeedsAnalysis.bmi.levels.overweight4');
};

export const jobPALFactorOptions = (user: User) => (
  Object.fromEntries(jobPalFactorsKeys.map((key) => {
    const palFactor = user.profile?.settings?.palFactors?.job[key] ? user.profile.settings.palFactors.job[key].toLocaleString() : 0;
    return [
      key,
      `${t('NeedsAnalysis.physicalLoad.palFactors.grade')} ${key} (${palFactor}): ${t(`NeedsAnalysis.physicalLoad.palFactors.job.${key}`)}`,
    ];
  })) as { [key in JobPalFactorKey]: string; }
);

export const freeTimePALFactorOptions = (user: User) => (
  Object.fromEntries(freeTimePalFactorsKeys.map((key) => {
    const palFactor = user.profile?.settings?.palFactors?.freeTime[key] ? user.profile.settings.palFactors.freeTime[key].toLocaleString() : 0;
    return [
      key,
      `${t('NeedsAnalysis.physicalLoad.palFactors.grade')} ${key} (${palFactor}): ${t(`NeedsAnalysis.physicalLoad.palFactors.freeTime.${key}`)}`,
    ];
  })) as { [key in FreeTimePalFactorKey]: string; }
);

export const ageByDateOfBirth = (dateOfBirth: string): number => (
  Math.floor(DateTime.now().diff(DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd'), 'years').years)
);

const bmrFormulas = {
  harris_benedict: (bodyWeight: number, bodySize: number, age: number, gender: Gender): number => {
    if (gender === 'male') {
      return roundNumber(66.473 + (13.752 * bodyWeight) + (5.003 * bodySize) - (6.755 * age), 0);
    }

    return roundNumber(655.096 + (9.563 * bodyWeight) + (1.85 * bodySize) - (4.676 * age), 0);
  },
  dge: (bodyWeight: number, age: number, gender: Gender): number => {
    if (gender === 'male') {
      return roundNumber(((0.047 * bodyWeight) + 1.009 - (0.01452 * age) + 3.21) * 239, 0);
    }

    return roundNumber(((0.047 * bodyWeight) - (0.01452 * age) + 3.21) * 239, 0);
  },
  bmi: (bodyWeight: number, bodySize: number, age: number, gender: Gender, fatFreeMass?: number): number => {
    const bmi = calcBMI(bodyWeight, bodySize);
    const weight = fatFreeMass ?? bodyWeight;

    if (bmi > 30) {
      return roundNumber(((0.05 * weight) + (1.103 * (gender === 'male' ? 1 : 0)) - (0.016 * age) + 2.924) * 239, 0);
    }

    if (bmi >= 25) {
      return roundNumber(((0.045 * weight) + (1.006 * (gender === 'male' ? 1 : 0)) - (0.015 * age) + 3.407) * 239, 0);
    }

    // formula is not applicable for BMI < 25
    return 0;
  },
  cunningham: (fatFreeMass: number): number => roundNumber(500 + (22 * fatFreeMass), 0),
  de_lorenzo: (bodyWeight: number, bodySize: number): number => roundNumber(-857 + (9 * bodyWeight) + (11.7 * bodySize), 0),
} as const;

interface CalcBmrArgs {
  bodyWeight?: number;
  fatFreeMass?: number;
  bodySize?: number;
  bodyFat?: number;
  dateOfBirth?: string;
  gender?: Gender,
}

export const calcBmr = (formula: BmrFormula, args: CalcBmrArgs) => {
  if (
    args.bodyWeight
    && args.bodySize
    && args.dateOfBirth
    && args.gender
  ) {
    const age = ageByDateOfBirth(args.dateOfBirth);
    const {
      bodyWeight, fatFreeMass, bodySize, gender,
    } = args;

    switch (formula) {
      case 'dge':
        return bmrFormulas.dge(fatFreeMass ?? bodyWeight, age, gender);
      case 'bmi':
        return bmrFormulas.bmi(bodyWeight, bodySize, age, gender, fatFreeMass);
      case 'de_lorenzo':
        return bmrFormulas.de_lorenzo(fatFreeMass ?? bodyWeight, bodySize);
      case 'cunningham':
        return fatFreeMass ? bmrFormulas.cunningham(fatFreeMass) : 0;
      default:
        return bmrFormulas.harris_benedict(fatFreeMass ?? bodyWeight, bodySize, age, gender);
    }
  }

  return 0;
};

export const getDemandForPregnancy = () => 255;

export const getDemandForBreastfeeding = (breastfeeding: BreastfeedingOption): number => {
  switch (breastfeeding) {
    case 'up_to_4_month':
      return 635;
    case 'after_4_month_full':
      return 525;
    case 'after_4_month_partial':
      return 285;
    default:
      return 0;
  }
};

const { getConfig } = useAppConfig();

export const enrichNeedsAnalysis = async (data: NeedsAnalysis, user: User, customer?: CustomerProfile): Promise<NeedsAnalysisObject> => {
  const customerProfile = customer ?? await getDoc(customerDoc(user.authUser.uid, data.customerId)).then((d) => d.data());

  const customerName = () => (
    customerProfile?.personalData?.firstName
    && customerProfile?.personalData?.surname
      ? `${customerProfile.personalData.firstName} ${customerProfile.personalData.surname}`
      : undefined
  );

  const fatFreeMass = () => (
    data.bodyWeight && data.bodyFat
      ? calcFatFreeMass(data.bodyWeight, data.bodyFat)
      : 0
  );

  const thq = () => (
    data.hipCircumference && data.waistCircumference
      ? calcTHQ(data.hipCircumference, data.waistCircumference)
      : 0
  );

  const bmi = () => (
    data.bodyWeight && data.bodySize
      ? calcBMI(data.bodyWeight, data.bodySize)
      : 0
  );

  const bmiLevel = () => {
    const _bmi = bmi();

    return _bmi ? getBMILevel(_bmi) : undefined;
  };

  const weeksUntilGoalDeadline = () => (
    data.goal?.deadline
      ? roundNumber(DateTime.fromFormat(data.goal.deadline, 'yyyy-MM-dd').diffNow('weeks').weeks, 0)
      : 0
  );

  const daysUntilGoalDeadline = () => (
    data.goal?.deadline
      ? roundNumber(DateTime.fromFormat(data.goal.deadline, 'yyyy-MM-dd').diffNow('days').days, 0)
      : 0
  );

  const bmr = (formula?: BmrFormula, payload?: CalcBmrArgs) => calcBmr(formula ?? data.bmrFormula ?? 'harris_benedict', payload ?? {
    bodyWeight: data.bodyWeight,
    fatFreeMass: data.considerFatFreeMass && data.bodyFat
      ? fatFreeMass()
      : undefined,
    bodySize: data.bodySize,
    bodyFat: data.bodyFat,
    dateOfBirth: customerProfile?.personalData?.dateOfBirth,
    gender: customerProfile?.personalData?.gender,
  });

  const pregnancyDemand = () => (
    data.pregnant
      ? getDemandForPregnancy()
      : 0
  );

  const breastfeedingDemand = () => (
    data.breastfeeding
      ? getDemandForBreastfeeding(data.breastfeeding)
      : 0
  );

  const bmrTotal = () => bmr() + pregnancyDemand() + breastfeedingDemand();

  const individualPalValue = () => {
    if (data.physicalLoad?.job
      && data.physicalLoad.freeTime
      && user.profile?.settings
      && data.dailySchedule) {
      const palJob = user.profile.settings.palFactors.job[data.physicalLoad.job];
      const palFreeTime = user.profile.settings.palFactors.freeTime[data.physicalLoad.freeTime];
      const palSleep = 0.95;

      const { work, freeTime, sleep } = data.dailySchedule;

      return roundNumber(((palJob * work) + (palFreeTime * freeTime) + (palSleep * sleep)) / 24, 2);
    }

    return 0;
  };

  const bmrTotalWithPal = () => roundNumber(individualPalValue() * bmrTotal(), 0);

  const sportsDemand = () => {
    const sportTypes = getConfig('sportTypes');
    if (sportTypes && data.sports && data.bodyWeight) {
      let demand = 0;

      data.sports.forEach((sport) => {
        const met = sportTypes.find((row) => row.id === parseInt(sport.id))?.met;

        if (met) {
          demand += sport.amount * met * (data.bodyWeight as number);
        }
      });

      return roundNumber(demand / 7, 0);
    }

    return 0;
  };

  const energyDemandSubtotal = () => bmrTotalWithPal() + sportsDemand();

  const goalDemand = () => {
    const days = daysUntilGoalDeadline();
    if (
      data.goal?.type
      && ['gain_weight', 'loose_weight'].includes(data.goal.type)
      && typeof data.goal.amount === 'number'
      && days
    ) {
      if (data.goal.type === 'loose_weight') {
        return roundNumber((-1 * data.goal.amount * 7000) / days, 0);
      }

      return roundNumber((data.goal.amount * 7000) / days, 0);
    }

    return 0;
  };

  const energyDemandTotal = () => energyDemandSubtotal() + goalDemand() + (data.manualEnergyAdjustment ?? 0);

  const energyAvailabilityThreshold = () => {
    const ffm = fatFreeMass();

    if (ffm) {
      return ffm * 30;
    }

    return 0;
  };

  return {
    ...data,
    customer: customerProfile,
    customerName,
    update: async (update: PartialDeep<NeedsAnalysis>) => {
      await updateNeedsAnalysis(user.authUser.uid, data.id, update);
    },
    fatFreeMass,
    thq,
    bmi,
    bmiLevel,
    weeksUntilGoalDeadline,
    daysUntilGoalDeadline,
    bmr,
    pregnancyDemand,
    breastfeedingDemand,
    bmrTotal,
    individualPalValue,
    bmrTotalWithPal,
    sportsDemand,
    energyDemandSubtotal,
    goalDemand,
    energyDemandTotal,
    energyAvailabilityThreshold,
    percentageCompleted: () => (
      roundNumber(((data.completedSections ?? 0) / 8) * 100, 0)
    ),
    jobPALFactorOptions: () => jobPALFactorOptions(user),
    freeTimePALFactorOptions: () => freeTimePALFactorOptions(user),
  };
};
