import {
  BehaviorSubject, combineLatest, concatMap, map, Observable, of, shareReplay, switchMap,
} from 'rxjs';
import type {
  Customer, CustomerLogEntry, CustomerLogEntryType, CustomerProfile, MaybeNull, NeedsAnalysis, ActivityPlan, NutritionPlan, NutritionPlanObject,
} from '@kcalc/lib';
import { collectionData } from 'rxfire/firestore';
import { orderBy, query, where } from 'firebase/firestore';
import type { QueryConstraint } from 'firebase/firestore';
import {
  consultant, customerProfile$, needsAnalysis$, typedCollection, typedSubCollection,
} from '@/lib/firebase/store';
import { userId$ } from '@/lib/firebase/auth';
import { enrichNutritionPlan } from '@/lib/nutrition-plan';

export const activeCustomerId$ = new BehaviorSubject<MaybeNull<string>>(null);
export const activeNeedsAnalysisId$ = new BehaviorSubject<MaybeNull<string>>(null);

const customer$ = (userId: string, customerId: string): Observable<Customer> => combineLatest([
  of(customerId),
  customerProfile$(userId, customerId),
]).pipe(
  map(([id, profile]) => ({
    id,
    profile,
  })),
);

export const activeCustomer$: Observable<MaybeNull<Customer>> = combineLatest([
  activeCustomerId$,
  userId$,
]).pipe(
  switchMap(([customerId, userId]) => {
    if (userId && customerId) {
      return customer$(userId, customerId);
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const customers$: Observable<MaybeNull<Customer[]>> = userId$.pipe(
  switchMap((userId) => {
    if (userId) {
      return collectionData(
        query(
          typedCollection<CustomerProfile & { id: string }>(`consultants/${userId}/customers`),
          orderBy('personalData.firstName', 'asc'),
          orderBy('personalData.surname', 'asc'),
        ),
        { idField: 'id' },
      );
    }

    return of(null);
  }),
  map((results) => {
    if (results) {
      return results.map((result) => ({
        id: result.id,
        profile: {
          ...Object.fromEntries(
            Object
              .keys(result)
              .filter((k) => k !== 'id')
              .map((k) => [k, result[k as keyof CustomerProfile]]),
          ) as unknown as CustomerProfile,
        },
      }));
    }

    return results;
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const needsAnalyses$: Observable<MaybeNull<NeedsAnalysis[]>> = combineLatest([
  activeCustomerId$,
  userId$,
]).pipe(
  switchMap(([customerId, userId]) => {
    if (userId && customerId) {
      return collectionData(query(
        typedSubCollection<NeedsAnalysis>(consultant(userId), 'needsAnalyses'),
        where('customerId', '==', customerId),
        orderBy('createdAt', 'desc'),
      ), { idField: 'id' });
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const activeNeedsAnalysis$: Observable<MaybeNull<NeedsAnalysis>> = combineLatest([
  userId$,
  activeNeedsAnalysisId$,
]).pipe(
  switchMap(([userId, needsAnalysisId]) => {
    if (userId && needsAnalysisId) {
      return needsAnalysis$(userId, needsAnalysisId).pipe(
        map((result) => result ?? null),
      );
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

const logEntriesObservables = new Map();
export const getLogEntriesObservable = (logEntryType?: CustomerLogEntryType): Observable<MaybeNull<CustomerLogEntry[]>> => {
  const key = logEntryType ?? 'generic';

  if (!logEntriesObservables.has(key)) {
    logEntriesObservables.set(
      key,
      combineLatest([
        activeCustomerId$,
        userId$,
      ]).pipe(
        switchMap(([customerId, userId]) => {
          if (userId && customerId) {
            const queryParams: QueryConstraint[] = [
              where('customerId', '==', customerId),
            ];

            if (logEntryType) {
              queryParams.push(where('type', '==', logEntryType));
            }

            queryParams.push(orderBy('createdAt', 'desc'));

            return collectionData(query(
              typedSubCollection<CustomerLogEntry>(consultant(userId), 'logEntries'),
              ...queryParams,
            ), { idField: 'id' });
          }

          return of(null);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      ),
    );
  }

  return logEntriesObservables.get(key);
};

export const activityPlans$: Observable<MaybeNull<ActivityPlan[]>> = combineLatest([
  activeCustomerId$,
  userId$,
]).pipe(
  switchMap(([customerId, userId]) => {
    if (userId && customerId) {
      return collectionData(query(
        typedSubCollection<ActivityPlan>(consultant(userId), 'activityPlans'),
        where('customerId', '==', customerId),
        orderBy('createdAt', 'desc'),
      ), { idField: 'id' });
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const nutritionPlans$: Observable<MaybeNull<NutritionPlanObject[]>> = combineLatest([
  activeCustomerId$,
  userId$,
]).pipe(
  switchMap(([customerId, userId]) => {
    if (userId && customerId) {
      return collectionData(
        query(
          typedSubCollection<NutritionPlan>(consultant(userId), 'nutritionPlans'),
          where('customerId', '==', customerId),
          orderBy('createdAt', 'desc'),
        ),
        { idField: 'id' },
      ).pipe(
        map((results) => (
          results.map((result) => enrichNutritionPlan(result, userId))
        )),
        concatMap((results) => Promise.all(results)),
      );
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);
