import {
  BehaviorSubject, combineLatest, concatMap, map, Observable, of, shareReplay, switchMap,
} from 'rxjs';
import type {
  MaybeNull, NeedsAnalysis, NutritionPlan, NutritionPlanObject,
} from '@kcalc/lib';
import type { PartialDeep } from 'type-fest';
import {
  getDoc, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import { userId$ } from '@/lib/firebase/auth';
import {
  consultant, customer as customerDoc, nutritionPlan$, typedSubCollection, updateNutritionPlan,
} from '@/lib/firebase/store';
import { enrichNeedsAnalysis } from '@/lib/needs-analysis';
import { useAuth } from '@/composables/firebase/auth';

export const activeNutritionPlanId$ = new BehaviorSubject<MaybeNull<string>>(null);
const { user } = useAuth();

export const enrichNutritionPlan = async (data: NutritionPlan, userId: string): Promise<NutritionPlanObject> => {
  const result: NutritionPlanObject = {
    ...data,
    update: async (update: PartialDeep<NutritionPlan>) => {
      await updateNutritionPlan(userId, data.id, update);
    },
  };

  if (data?.customerId) {
    result.customer = {
      id: data.customerId,
      profile: await getDoc(customerDoc(userId, data.customerId)).then((d) => d.data()),
    };
    result.needsAnalysis = await getDocs(query(
      typedSubCollection<NeedsAnalysis>(consultant(userId), 'needsAnalyses'),
      where('customerId', '==', data.customerId),
      orderBy('createdAt', 'desc'),
      limit(1),
    )).then((snapshot) => {
      if (snapshot.docs.length > 0) {
        const doc = snapshot.docs[0];

        return {
          ...doc.data(),
          id: doc.id,
        } as NeedsAnalysis;
      }

      return undefined;
    }).then((doc) => {
      if (doc && user.value) {
        return enrichNeedsAnalysis(doc, user.value, result.customer?.profile);
      }

      return undefined;
    });
  }

  return result;
};

export const activeNutritionPlan$: Observable<MaybeNull<NutritionPlanObject>> = combineLatest([
  userId$,
  activeNutritionPlanId$,
]).pipe(
  switchMap(([userId, nutritionPlanId]) => {
    if (userId && nutritionPlanId) {
      return nutritionPlan$(userId, nutritionPlanId).pipe(switchMap((data) => (data ? enrichNutritionPlan(data, userId) : of(null))));
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const latestNutritionPlans$: Observable<MaybeNull<NutritionPlanObject[]>> = userId$.pipe(
  switchMap((userId) => {
    if (userId) {
      return collectionData(
        query(
          typedSubCollection<NutritionPlan>(consultant(userId), 'nutritionPlans'),
          orderBy('createdAt', 'desc'),
          // TODO implement load more feature instead of high limit
          limit(100),
        ),
        { idField: 'id' },
      ).pipe(
        map((results) => (
          results.map((result) => enrichNutritionPlan(result, userId))
        )),
        concatMap((results) => Promise.all(results)),
      );
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);
