import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  from,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  timer,
} from 'rxjs';
import type {
  ActivityPlan,
  CustomerLogEntry,
  CustomerLogEntryType,
  MaybeNull,
  MaybeUndefined,
  NeedsAnalysis,
  OnboardingStep,
  SpecialAccess,
  User,
  UserProfile,
  UserRole,
  VerificationEmailPayload,
} from '@kcalc/lib';
import { onboardingSteps } from '@kcalc/lib';
import { authState } from 'rxfire/auth';
import type { User as AuthUser } from 'firebase/auth';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  EmailAuthProvider,
  getAdditionalUserInfo,
  OAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { useTimeoutPoll } from '@vueuse/core';
import { DateTime } from 'luxon';
import { collectionData } from 'rxfire/firestore';
import {
  getDoc, limit, orderBy, query, where,
} from 'firebase/firestore';
import type { QueryConstraint } from 'firebase/firestore';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { auth } from '@/lib/firebase/app';
import {
  consultant,
  consultant$,
  customer as customerDoc,
  toggleConsultantCollectionFieldEntry,
  typedSubCollection,
  updateConsultant,
} from '@/lib/firebase/store';
import { getCredentialsForUser$, getSgdOidcSettings$, sendVerificationEmail$ } from '@/lib/firebase/functions';
import {
  addPointsForAction, getLevel, getPoints, getTotalPoints,
} from '@/lib/gamification';
import { completeStep, skipStep } from '@/lib/onboarding';
import { disableAnalytics, enableAnalytics, trackEvent } from '@/lib/analytics';
import { addUsageForRecipe, setLikesForRecipe, submitRecipeToMainPlatform } from '@/lib/backend';
import { isPlatform } from '@/lib/platform';
import { onAfterAuthenticated } from '@/lib/hooks/sgd';

const userRoles$ = (user: AuthUser): Observable<UserRole[]> => from(user.getIdTokenResult()).pipe(
  map((result) => (result?.claims?.roles ?? []) as UserRole[]),
);

const combinedUser$ = (user: AuthUser): Observable<User> => combineLatest([
  of(user),
  userRoles$(user),
  consultant$(user.uid),
  getCredentialsForUser$(user.uid),
]).pipe(
  // onAfterAuthenticated guard operator
  switchMap(async (source) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    const [authUser, _, profile, credentials] = source;

    if (isPlatform('sgd') && profile) {
      await onAfterAuthenticated(authUser, profile, credentials.sgdTokens?.access_token);
    }

    return source;
  }),
  map(([authUser, roles, profile, credentials]) => ({
    authUser,
    roles,
    profile,
    credentials,
    updateProfile: (update) => updateConsultant(authUser.uid, update),
    addPointsForAction: (action, points) => addPointsForAction(authUser.uid, action, points),
    getPoints: () => getPoints(authUser, profile?.verifiedAt?.timestamp),
    getTotalPoints: () => getTotalPoints(authUser, profile?.verifiedAt?.timestamp),
    getLevel: () => getLevel(authUser, profile?.verifiedAt?.timestamp),
    toggleFoodFavorite: (foodId) => toggleConsultantCollectionFieldEntry(authUser.uid, 'favoriteFoodIds', foodId),
    toggleRecipeFavorite: (recipeId) => toggleConsultantCollectionFieldEntry(authUser.uid, 'favoriteRecipeIds', recipeId),
    toggleProductFavorite: (productId) => toggleConsultantCollectionFieldEntry(authUser.uid, 'favoriteProductIds', productId),
    toggleRecipeLike: async (recipeId, platform, likeChange) => {
      await authUser.getIdToken().then((token) => setLikesForRecipe(recipeId, platform, likeChange, token));
      await toggleConsultantCollectionFieldEntry(authUser.uid, 'likedRecipeIds', recipeId);
    },
    addRecipeUsage: (recipeId, platform) => authUser.getIdToken().then((token) => addUsageForRecipe(recipeId, platform, token)),
    submitRecipeToMainPlatform: (recipeId, platform) => authUser.getIdToken().then((token) => submitRecipeToMainPlatform(recipeId, platform, token)),
    completeOnboardingStep: (step: OnboardingStep) => completeStep(authUser.uid, step),
    skipOnboardingStep: (step: OnboardingStep) => skipStep(authUser.uid, step),
  })),
);

const userRefreshPing = new BehaviorSubject(null);

const bufferedAuthState$ = combineLatest([
  authState(auth),
  timer(600),
  userRefreshPing,
]).pipe(
  map(([authState]) => authState),
);

export const user$: Observable<User | null> = bufferedAuthState$.pipe(
  switchMap(
    (authUser) => (authUser
      ? combinedUser$(authUser)
      : of(null)),
  ),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const userId$: Observable<MaybeNull<string>> = user$.pipe(
  map((user) => user?.authUser?.uid ?? null),
);

userId$.subscribe(async (userId) => {
  if (userId) {
    await enableAnalytics();
  } else {
    await disableAnalytics();
  }
});

export const isAuthenticated$: Observable<boolean> = user$.pipe(
  map(Boolean),
  distinctUntilChanged(),
);

export const isThirdPartyLogin$: Observable<boolean> = user$.pipe(
  map((user) => user?.authUser?.providerData[0].providerId !== 'password'),
  distinctUntilChanged(),
);

export const isVerified$: Observable<boolean> = user$.pipe(
  map((user) => {
    if (user?.authUser?.providerData[0].providerId !== 'password') {
      return Boolean(user?.profile?.verifiedAt?.iso);
    }

    return Boolean(user?.authUser?.emailVerified);
  }),
  distinctUntilChanged(),
);

export const hasCompletedOnboarding$: Observable<boolean> = user$.pipe(
  map((user) => {
    if (user?.profile?.onboardingSteps) {
      return Object
        .values(user.profile.onboardingSteps)
        .filter((v) => v === true || v === 'skipped')
        .length === onboardingSteps.length;
    }

    return false;
  }),
  distinctUntilChanged(),
);

export const hasActiveTrial = (profile: UserProfile): boolean => {
  if (profile.trialUntil) {
    return DateTime.fromISO(profile.trialUntil) >= DateTime.now();
  }

  return false;
};

export const hasActiveTrial$: Observable<boolean> = user$.pipe(
  map((user) => (user?.profile ? hasActiveTrial(user.profile) : false)),
  distinctUntilChanged(),
);

export const canAccessCheckout$: Observable<boolean> = user$.pipe(
  map((user) => !(user?.profile?.subscription && ['active', 'suspended'].includes(user.profile.subscription.status))),
);

export const hasActiveSubscription$: Observable<boolean> = user$.pipe(
  map((user) => {
    if (user?.profile?.subscription) {
      if (user.profile.subscription.status === 'active') {
        return true;
      }

      // Accept canceled subscription if not expired yet
      if (user.profile.subscription.status === 'canceled' && user.profile.subscription.endDate) {
        const creationDate = DateTime.fromISO(user.profile.subscription.createdAt, { zone: 'utc' });

        const endDate = DateTime
          .fromISO(user.profile.subscription.endDate, { zone: 'utc' })
          .set({
            hour: creationDate.hour,
            minute: creationDate.minute,
            second: creationDate.second,
          });

        return endDate > DateTime.now();
      }

      // Accept suspended subscriptions during grace period
      if (user.profile.subscription.status === 'suspended' && user.profile.subscription.suspendedAt) {
        return DateTime.fromISO(user.profile.subscription.suspendedAt).plus({ days: 7 }) > DateTime.now();
      }
    }

    return false;
  }),
  distinctUntilChanged(),
);

export const getSpecialAccess = (profile: UserProfile): MaybeNull<SpecialAccess> => {
  if (profile.specialAccesses && Array.isArray(profile.specialAccesses)) {
    const activeAccesses = profile.specialAccesses.filter((access) => access.until > DateTime.now().toISO());
    if (activeAccesses.length > 0) {
      return activeAccesses.sort((a, b) => b.until.localeCompare(a.until))[0];
    }
  }

  return null;
};

export const hasSpecialAccess = (profile: UserProfile): boolean => Boolean(getSpecialAccess(profile));

export const specialAccess$: Observable<MaybeNull<SpecialAccess>> = user$.pipe(
  map((user) => (user?.profile ? getSpecialAccess(user.profile) : null)),
);

export const hasActiveSpecialAccess$: Observable<boolean> = specialAccess$.pipe(
  map(Boolean),
);

export const hasAccess$: Observable<boolean> = combineLatest([
  hasActiveTrial$,
  hasActiveSubscription$,
  hasActiveSpecialAccess$,
]).pipe(
  map(([hasTrial, hasSubscription, hasSpecialAccess]) => hasTrial || hasSubscription || hasSpecialAccess),
  distinctUntilChanged(),
);

export const hasBusinessAccess$: Observable<boolean> = hasAccess$.pipe(
  filter(Boolean),
  switchMap(() => user$),
  switchMap(() => combineLatest(
    [
      hasActiveTrial$,
      hasActiveSubscription$,
      specialAccess$,
      user$,
    ],
  )),
  map(([hasTrial, hasSubscription, specialAccess, user]) => {
    if (specialAccess) {
      return specialAccess.plan === 'business';
    }

    if (hasSubscription) {
      return user?.profile?.subscription?.plan === 'business';
    }

    return hasTrial;
  }),
  distinctUntilChanged(),
);

export const login = async (email: string, password: string) => {
  const response = await signInWithEmailAndPassword(auth, email, password);

  enableAnalytics().then(() => {
    trackEvent('login');
  });

  return response;
};

export const loginWithCredential = (idToken: string, providerId: string) => {
  const provider = new OAuthProvider(providerId);
  const credential = provider.credential({ idToken });

  return signInWithCredential(auth, credential).then((userCredential) => ({
    ...userCredential,
    isNewUser: getAdditionalUserInfo(userCredential)?.isNewUser,
  }));
};

export const registerWithEmailAndPassword = (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password);

export const logout = () => signOut(auth);

export const resetPassword = (email: string) => sendPasswordResetEmail(auth, email);

export const getUtmTagsFromUrl = (): MaybeUndefined<string> => {
  const tagAllowList = ['campaign', 'source', 'term', 'content', 'medium'];
  const utmTags: Record<string, string> = window.location.search
    .substring(1)
    .split('&')
    .filter((pair) => tagAllowList.includes(pair.split('=')[0].replace('utm_', '')))
    .reduce((tags, pair) => ({ ...tags, [pair.split('=')[0]]: pair.split('=')[1] }), {});

  if (Object.keys(utmTags).length > 0) {
    return JSON.stringify(utmTags);
  }
};

export const sendVerificationLink = async (authUser?: AuthUser) => {
  const user = authUser ?? await lastValueFrom<AuthUser>(user$.pipe(
    filter(Boolean),
    take(1),
    map((user) => user.authUser),
  ));

  if (!user) {
    throw new Error('No logged user found');
  }

  let payload: VerificationEmailPayload = {
    continueUrl: window.location.origin,
  };

  const utmTags = getUtmTagsFromUrl();
  if (utmTags) {
    payload = {
      ...payload,
      utmTags,
    };
  }

  await lastValueFrom(sendVerificationEmail$(payload));

  const { pause } = useTimeoutPoll(
    async () => {
      await user.reload();

      if (user.emailVerified) {
        userRefreshPing.next(null);
        pause();
      }
    },
    2500,
    {
      immediate: true,
    },
  );
};

export const reauthenticate = async (password: string) => {
  const user = await lastValueFrom<AuthUser>(user$.pipe(
    filter(Boolean),
    take(1),
    map((user) => user.authUser),
  ));

  if (!user || !user.email) {
    throw new Error('No logged user found');
  }

  const authCredential = EmailAuthProvider.credential(user.email, password);

  const response = await reauthenticateWithCredential(user, authCredential);

  console.log(response);
};

export const deleteAccount = async () => {
  const user = await lastValueFrom<AuthUser>(user$.pipe(
    filter(Boolean),
    take(1),
    map((user) => user.authUser),
  ));

  if (!user) {
    throw new Error('No logged user found');
  }

  await deleteUser(user);
};

export const latestUserNeedsAnalyses$: Observable<MaybeNull<NeedsAnalysis[]>> = userId$.pipe(
  switchMap((userId) => {
    if (userId) {
      return collectionData(
        query(
          typedSubCollection<NeedsAnalysis>(consultant(userId), 'needsAnalyses'),
          orderBy('createdAt', 'desc'),
          // TODO implement load more feature instead of high limit
          limit(100),
        ),
        { idField: 'id' },
      );
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

const latestLogEntriesObservables = new Map();
export const getLatestLogEntriesObservable = (logEntryType?: CustomerLogEntryType): Observable<MaybeNull<CustomerLogEntry[]>> => {
  const key = logEntryType ?? 'generic';

  if (!latestLogEntriesObservables.has(key)) {
    latestLogEntriesObservables.set(
      key,
      userId$.pipe(
        switchMap((userId) => {
          if (userId) {
            const queryParams: QueryConstraint[] = [];

            if (logEntryType) {
              queryParams.push(where('type', '==', logEntryType));
            }

            queryParams.push(orderBy('createdAt', 'desc'));
            // TODO implement load more feature instead of high limit
            queryParams.push(limit(100));

            return collectionData(query(
              typedSubCollection<CustomerLogEntry>(consultant(userId), 'logEntries'),
              ...queryParams,
            ), { idField: 'id' }).pipe(
              map((results) => (
                results.map((result) => (
                  getDoc(customerDoc(userId, result.customerId)).then((d) => ({
                    ...result,
                    customer: d.data(),
                  }))))
              )),
              concatMap((results) => Promise.all(results)),
            );
          }

          return of(null);
        }),
        shareReplay({ bufferSize: 1, refCount: false }),
      ),
    );
  }

  return latestLogEntriesObservables.get(key);
};

export const latestActivityPlans$: Observable<MaybeNull<ActivityPlan[]>> = userId$.pipe(
  switchMap((userId) => {
    if (userId) {
      return collectionData(query(
        typedSubCollection<ActivityPlan>(consultant(userId), 'activityPlans'),
        orderBy('createdAt', 'desc'),
        // TODO implement load more feature instead of high limit
        limit(100),
      ), { idField: 'id' }).pipe(
        map((results) => (
          results.map((result) => (
            getDoc(customerDoc(userId, result.customerId)).then((d) => ({
              ...result,
              customer: d.data(),
            }))))
        )),
        concatMap((results) => Promise.all(results)),
      );
    }

    return of(null);
  }),
  shareReplay({ bufferSize: 1, refCount: false }),
);

export const getSgdUserManager = (): Promise<UserManager> => lastValueFrom(
  getSgdOidcSettings$().pipe(
    take(1),
    map((settings) => new UserManager({
      ...settings,
      redirect_uri: ((url) => {
        url.pathname = 'login';
        url.searchParams.set('callback', 'sgd');

        return url.toString();
      })(new URL(window.location.origin)),
      loadUserInfo: true,
      stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    })),
  ),
);

export const clearOidcUrlParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('callback');
  url.searchParams.delete('state');
  url.searchParams.delete('session_state');
  url.searchParams.delete('code');

  window.history.replaceState({}, document.title, url);
};
