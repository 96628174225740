<script setup lang="ts">
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { computed } from 'vue';

interface Props {
  loading?: boolean;
  progress?: number;
  icon?: IconProp;
}

const props = defineProps<Props>();

// @ts-ignore
const showProgress = computed(() => (typeof props.progress !== 'undefined' && props.loading));
</script>

<template>
  <button
    v-bind="$attrs"
    :class="{ 'ld-ext-left running': loading, 'in-progress': showProgress }"
    :disabled="loading"
    class="btn">
    <span
      v-if="showProgress"
      class="progress">
      <span :style="{ width: `${progress}%` }" />
    </span>

    <font-awesome-icon
      v-if="icon"
      class="mr-2"
      :icon="icon" />

    <slot />
    <span
      v-if="loading"
      class="ld ld-ring ld-spin" />
  </button>
</template>
