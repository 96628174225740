import type {
  LocationQueryRaw, RouteLocationNormalized, RouteLocationPathRaw, RouteRecordRaw,
} from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { Component } from 'vue';
import { filter, lastValueFrom, take } from 'rxjs';
import qs from 'qs';
import type { MaybeUndefined } from '@kcalc/lib';
import { head } from '@/lib/head';
import IconDashboard from '@/components/icons/IconDashboard.vue';
import IconConsulting from '@/components/icons/IconConsulting.vue';
import IconCustomers from '@/components/icons/IconCustomers.vue';
import IconFood from '@/components/icons/IconFood.vue';
import IconRecipes from '@/components/icons/IconRecipes.vue';
import IconManagement from '@/components/icons/IconManagement.vue';
import { t } from '@/lib/i18n';
import BrandIcon from '@/components/brand/BrandIcon.vue';
import { canAccessCheckout$, hasBusinessAccess$, isVerified$ } from '@/lib/firebase/auth';
import { useSystemMessage } from '@/composables/system-message';
import { useCheckout } from '@/composables/checkout';
import { activeCustomerId$, activeNeedsAnalysisId$ } from '@/lib/customer';
import { activeNutritionPlan$, activeNutritionPlanId$ } from '@/lib/nutrition-plan';
import { isPlatform } from '@/lib/platform';

interface MenuEntry {
  title: string;
  position: 'main';
  icon?: Component; // TODO how to load another component here?
}

const { setSystemMessageByCode } = useSystemMessage();

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    bypassAuth?: boolean;
    hideAppSidebar?: boolean;
    menu?: MenuEntry;
    bypassAccessControl?: boolean;
  }
}

let backUrl: MaybeUndefined<string>;

const getSanitizedQuery = (fullPath: string, isAuthenticated: boolean) => {
  const url = new URL(`${window.location.origin}/${fullPath}`);
  if (isAuthenticated) {
    url.searchParams.delete('callback');
    url.searchParams.delete('state');
    url.searchParams.delete('session_state');
    url.searchParams.delete('code');
  }

  return qs.parse(url.search, { ignoreQueryPrefix: true }) as unknown as LocationQueryRaw;
};

// TODO 404 page
export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/DashboardView.vue'),
    meta: {
      title: 'routes.dashboard.menuTitle',
      menu: {
        title: t('routes.dashboard.menuTitle'),
        position: 'main',
        icon: IconDashboard,
      },
    },
  },
  {
    path: '/consulting',
    name: 'consulting',
    component: () => import('@/views/consulting/ConsultingView.vue'),
    meta: {
      title: 'routes.consulting.menuTitle',
      menu: {
        title: t('routes.consulting.menuTitle'),
        position: 'main',
        icon: IconConsulting,
      },
    },
    children: [
      {
        path: '',
        name: 'consultingDashboard',
        component: () => import('@/views/consulting/ConsultingDashboard.vue'),
      },
      {
        path: 'nutrition-plan/:nutritionPlanId',
        component: () => import('@/views/consulting/nutrition-plan/NutritionPlanView.vue'),
        children: [
          {
            path: '',
            name: 'nutritionPlan',
            redirect: {
              name: 'nutritionPlanDayView',
            },
          },
          {
            path: 'day',
            name: 'nutritionPlanDayView',
            component: () => import('@/views/consulting/nutrition-plan/NutritionPlanDayView.vue'),
            meta: {
              title: 'routes.consulting.nutritionPlan',
            },
          },
          {
            path: 'week',
            name: 'nutritionPlanWeekView',
            component: () => import('@/views/consulting/nutrition-plan/NutritionPlanWeekView.vue'),
            meta: {
              title: 'routes.consulting.nutritionPlan',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/customer/CustomersView.vue'),
    meta: {
      title: 'routes.customers.menuTitle',
      menu: {
        title: t('routes.customers.menuTitle'),
        position: 'main',
        icon: IconCustomers,
      },
    },
    children: [
      {
        path: '',
        name: 'customerList',
        component: () => import('@/views/customer/CustomerList.vue'),
      },
      {
        path: ':customerId',
        name: 'customerView',
        component: () => import('@/views/customer/CustomerView.vue'),
        children: [
          {
            path: '',
            name: 'customerDashboard',
            component: () => import('@/views/customer/CustomerDashboard.vue'),
          },
          {
            path: 'needs-analysis/:needsAnalysisId',
            name: 'needsAnalysis',
            component: () => import('@/views/consulting/NeedsAnalysisView.vue'),
            meta: {
              title: 'routes.customers.needsAnalysis',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/food',
    name: 'food',
    component: () => import('@/views/FoodView.vue'),
    meta: {
      title: 'routes.food.menuTitle',
      menu: {
        title: t('routes.food.menuTitle'),
        position: 'main',
        icon: IconFood,
      },
    },
  },
  {
    path: '/recipes',
    name: 'recipes',
    component: () => import('@/views/RecipesView.vue'),
    meta: {
      title: 'routes.recipes.menuTitle',
      menu: {
        title: t('routes.recipes.menuTitle'),
        position: 'main',
        icon: IconRecipes,
      },
    },
  },
  {
    path: '/management',
    name: 'management',
    component: () => import('@/views/management/ManagementView.vue'),
    meta: {
      title: 'routes.management.menuTitle',
      menu: {
        title: t('routes.management.menuTitle'),
        position: 'main',
        icon: IconManagement,
      },
    },
    children: [
      {
        path: '',
        name: 'managementRoot',
        redirect: {
          name: 'accounting',
        },
      },
      {
        path: 'accounting',
        name: 'accounting',
        component: () => import('@/views/management/AccountingView.vue'),
        meta: {
          title: 'routes.management.accounting',
        },
        beforeEnter(to, from, next) {
          hasBusinessAccess$.pipe(
            take(1),
          ).subscribe((granted: boolean) => {
            if (granted) {
              next();
            } else {
              next({ name: 'settings' });
            }
          });
        },
      },
      {
        path: 'design',
        name: 'design',
        component: () => import('@/views/management/DesignView.vue'),
        meta: {
          title: 'routes.management.design',
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/management/SettingsView.vue'),
        meta: {
          title: 'routes.management.settings',
        },
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'routes.profile.menuTitle',
      bypassAccessControl: true,
    },
    component: () => import('@/views/ProfileView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/AuthView.vue'),
    meta: {
      title: 'routes.login.title',
      bypassAuth: true,
      bypassAccessControl: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/AuthView.vue'),
    meta: {
      title: 'routes.register.title',
      bypassAuth: true,
      bypassAccessControl: true,
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/checkout/CheckoutView.vue'),
    meta: {
      title: 'routes.checkout.title',
      hideAppSidebar: true,
      bypassAccessControl: true,
    },
    beforeEnter(to, from, next) {
      canAccessCheckout$.pipe(
        take(1),
      ).subscribe((canAccess: boolean) => {
        if (canAccess) {
          next();
        } else {
          next({ name: 'profile' });
        }
      });
    },
    children: [
      {
        path: '',
        name: 'checkout',
        redirect: '/checkout/plan',
      },
      {
        path: 'plan',
        name: 'choosePlan',
        component: () => import('@/views/checkout/ChoosePlanView.vue'),
        meta: {
          title: 'routes.checkout.choosePlanTitle',
        },
      },
      {
        path: 'personal-data',
        name: 'checkoutPersonalData',
        component: () => import('@/views/checkout/CheckoutPersonalDataView.vue'),
        meta: {
          title: 'routes.checkout.personalDataTitle',
        },
      },
      {
        path: 'overview',
        name: 'checkoutOverview',
        component: () => import('@/views/checkout/CheckoutOverviewView.vue'),
        beforeEnter(to, from, next) {
          const { hasRequiredPersonalData, subscriptionPlanCode } = useCheckout();

          if (!subscriptionPlanCode.value) {
            next({ name: 'choosePlan' });
          } else if (!hasRequiredPersonalData.value) {
            next({ name: 'checkoutPersonalData' });
          } else {
            next();
          }
        },
        meta: {
          title: 'routes.checkout.overviewTitle',
        },
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      title: 'routes.error.title',
      bypassAuth: true,
      bypassAccessControl: true,
      hideAppSidebar: true,
    },
  },
];

if (import.meta.env.DEV) {
  routes.push({
    path: '/styleguide',
    name: 'styleguide',
    meta: {
      title: 'Styleguide',
      menu: {
        title: 'Styleguide',
        position: 'main',
        icon: BrandIcon,
      },
    },
    component: () => import('@/views/StyleguideView.vue'),
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query?.message && typeof to.query.message === 'string') {
    setSystemMessageByCode(to.query.message);
  }

  activeCustomerId$.next(to?.params?.customerId ? to.params.customerId as string : null);
  activeNeedsAnalysisId$.next(to?.params?.needsAnalysisId ? to.params.needsAnalysisId as string : null);
  activeNutritionPlanId$.next(to?.params?.nutritionPlanId ? to.params.nutritionPlanId as string : null);

  isVerified$.pipe(
    take(1),
  ).subscribe((isAuthenticated) => {
    if (!isAuthenticated && to.meta.bypassAuth !== true) {
      if (routes.find((route) => route.path === to.path)) {
        backUrl = to.fullPath;
      }

      const redirTarget = isPlatform('sgd') ? 'login' : 'register';
      next({ name: redirTarget, query: to.query });
      return;
    }

    if (isAuthenticated && (['login', 'register'].includes(to.name as string) || backUrl)) {
      if (backUrl) {
        next(backUrl);
      } else {
        next({ path: '/', query: to.query });
      }

      if (backUrl) {
        backUrl = undefined;
      }

      return;
    }

    // Keep query if the new destination has no custom one specified
    const hasQuery = (route: RouteLocationNormalized) => {
      const q = getSanitizedQuery(route.fullPath, isAuthenticated);

      return q && Object.keys(q).length > 0;
    };

    if (!hasQuery(to) && hasQuery(from)) {
      next({ ...to, query: getSanitizedQuery(from.fullPath, isAuthenticated) });
    } else {
      next();
    }
  });
});

router.afterEach(async (to) => {
  const key = to.meta.title;

  if (key) {
    const translationPayload: Record<string, unknown> = {};

    const activeNutritionPlan = to.params?.nutritionPlanId ? await lastValueFrom(activeNutritionPlan$.pipe(filter(Boolean), take(1))) : null;
    if (activeNutritionPlan && activeNutritionPlan.title) {
      translationPayload.nutritionPlanTitle = activeNutritionPlan.title;
    }

    const title = t(key, translationPayload);
    head.push({
      title,
    });
  } else {
    head.push({
      title: undefined,
    });
  }

  isVerified$.pipe(
    take(1),
  ).subscribe((isAuthenticated) => {
    if (isAuthenticated && to.query.p) {
      const url = new URL(window.location.href);
      url.searchParams.delete('p');
      history.replaceState({}, document.title, url.href);
    }
  });
});

isVerified$.subscribe((isAuthenticated) => {
  console.log('[router] is authenticated:', isAuthenticated);

  const path = window.location.pathname;
  const isAuthPath = ['/login', '/register'].includes(path);
  const redirPath = isPlatform('sgd') ? '/login' : '/register';
  const to: RouteLocationPathRaw = isAuthenticated
    ? { path: backUrl ?? (!isAuthPath ? path : '/') }
    : { path: isAuthPath ? path : redirPath };

  if (location.search) {
    to.query = getSanitizedQuery(window.location.search, isAuthenticated);
  }

  router
    .push({
      ...to,
      force: true,
    })
    .catch((e) => console.warn('[Router] Failed to redirect after auth state changed', e));
});

export default router;
