import type { MaybeUndefined, AppConfig } from '@kcalc/lib';
import { readonly, ref } from 'vue';
import { useTypesense } from '@/composables/typesense';

interface Options {
  autoFetch?: boolean;
}

const cachedConfig = ref<MaybeUndefined<AppConfig>>();

export function useAppConfig(options?: Options) {
  async function fetch(): Promise<MaybeUndefined<AppConfig>> {
    const { getClient } = useTypesense();

    try {
      return getClient('read')?.collections<AppConfig>('app_config')
        .documents()
        .search({
          q: '*',
          query_by: '',
        })
        .then((response) => {
          const config = response.hits?.[0].document;
          if (config) {
            cachedConfig.value = config;
          }

          return config;
        });
    } catch (e) {
      // noop
    }
  }

  if (!cachedConfig.value && options?.autoFetch !== false) {
    fetch().finally(() => console.log('[app-config] auto fetched app config'));
  }

  return {
    config: readonly(cachedConfig),
    getConfig: <P extends keyof AppConfig>(property: P) => cachedConfig.value?.[property],
    fetch,
  };
}
